import { useEffect, useState } from 'react'
import { useAsyncFn, useInterval } from 'react-use'
import loginBtnImg from '@/assets/images/login/login_btn.png'
import closeImg from '@/assets/images/login/close.png'
import { loginModalState, userState } from '@/store/global'
import { useAtom, useSetAtom } from 'jotai'
import Modal from '../Modal'
import { codeLogin, sendCode } from '@/api/global'
import { Slide, toast } from 'react-toastify'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default () => {
  const [loginModal, setLoginModal] = useAtom(loginModalState)
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [timeOut, setTimeOut] = useState(0)
  const setUser = useSetAtom(userState)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  //
  const [codeData, codeFetch] = useAsyncFn(async () => {
    if (phone.length < 11) {
      toast('请输入11位手机号')
      return ''
    }
    const res = await sendCode({ phone })
    if (res.code !== 200) {
      toast(res.msg)
      return ''
    }
    setTimeOut(60)
    return res.data
  }, [phone])

  //
  const [loginData, loginFetch] = useAsyncFn(async () => {
    if (phone === '') {
      toast('请输入手机号')
      return ''
    }
    if (code === '') {
      toast('请输入验证码')
      return ''
    }
    const res = await codeLogin({ phone, code })
    if (res.code !== 200) {
      toast(res.msg)
      return ''
    }
    console.log(res)
    localStorage.token = res.data.token
    localStorage.user = JSON.stringify(res.data)
    setUser(res.data)
    setLoginModal(false)
    const from = searchParams.get('from')
    from && navigate(from, { replace: true })
    return res.data
  }, [phone, code])

  // 轮询详情
  useInterval(() => setTimeOut((v) => v - 1), timeOut > 0 ? 1000 : null)

  const handleChange = (e: { target: { value: any } }) => {
    const value = e.target.value
    // 只允许数字
    if (/^[0-9]{0,11}$/.test(value)) {
      setPhone(value)
    }
  }

  const handleCodeChange = (e: { target: { value: any } }) => {
    const value = e.target.value
    // 只允许数字
    if (/^[0-9]{0,6}$/.test(value)) {
      setCode(value)
    }
  }

  return (
    <Modal show={loginModal}>
      <div className="w-screen h-screen relative flex_center shadow-md">
        {/* <div className="w-full h-full absolute top-0 left-0 z-0 bg-[#00000010]"></div> */}
        <div
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
          className="w-[542px] h-[443px] bg-white relative z-10 rounded-[15px] px-[18px]"
        >
          <img
            onClick={() => {
              setLoginModal(false)
              if (location.pathname === '/login') {
                navigate('/')
              }
            }}
            className="anim_btn absolute right-[10px] top-[10px] w-[29px] h-[29px]"
            src={closeImg}
          />

          <div
            onClick={() => {
              if (import.meta.env.VITE_APP_ENV !== 'live') {
                setPhone('18788889999')
                setCode('911999')
              }
            }}
            className="text-center commonColor leading-none text-[##5567E7] text-[28px] mt-[40px] mb-[13px]"
          >
            欢迎登录
          </div>
          {/* <div
            onClick={() => {
              if (import.meta.env.VITE_APP_ENV !== 'live') {
                setPhone('18788889991')
                setCode('911999')
              }
            }}
            className="text-[14px] leading-none text-black"
          >
            中国美术学院少儿数字艺术AI创作课程
          </div> */}
          <div className="leading-none text-[19px] mt-[51px]">手机号码</div>
          <div className="relative mt-[9px] h-[35px] border-b-[1px] border-black border-solid">
            <input value={phone} onChange={handleChange} maxLength={11} className="border-none w-full h-full p-0" placeholder="请输入手机号" />
          </div>
          <div className="leading-none  text-[19px] mt-[30px]">验证码</div>
          <div className="relative mt-[7px] h-[35px] border-b-[1px] border-black border-solid">
            <input value={code} onChange={handleCodeChange} maxLength={6} className="border-none w-full h-full p-0" placeholder="请输入验证码" type="text" />
            <div
              onClick={timeOut === 0 ? codeFetch : undefined}
              className={`absolute right-0 bottom-[2px] w-[104px] h-[32px] text-[19px] text-white flex_center rounded-[5px] ${timeOut === 0 ? 'anim_btn commonColor' : 'bg-[#ddd]'}`}
            >
              {timeOut === 0 ? '获取验证码' : `${timeOut}s`}
            </div>
          </div>
          <div className="flex justify-center">
            <div className="mt-[70px] login_btn" onClick={loginFetch}>
              登录
              {/* <img onClick={loginFetch} className="w-[322px] h-[50px] anim_btn" src={loginBtnImg} alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
