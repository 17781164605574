import { Plugin } from 'xgplayer'
import { IPluginOptions } from 'xgplayer/es/plugin/plugin'

const { POSITIONS } = Plugin

export default class TryPlugin extends Plugin {
  ret: HTMLElement | null = null
  next: HTMLElement | null = null
  ai: HTMLElement | null = null
  rePlay: Function | null = null
  toNext: Function | null = null
  jumpAi: Function | null = null
  // 插件的名称，将作为插件实例的唯一key值
  static get pluginName() {
    return 'tryPlugin'
  }

  static get defaultConfig() {
    return {
      // 挂载在controls的右侧，如果不指定则默认挂载在播放器根节点上
      position: POSITIONS.ROOT,
      // 是否显示下一节按钮
      isNext: true,
      // 重播
      rePlay: null,
      // 跳下一节
      toNext: null,
      // 跳ai
      jumpAi: null
    }
  }

  constructor(args: IPluginOptions | undefined) {
    super(args)
  }

  beforePlayerInit() {
    // TODO 播放器调用start初始化播放源之前的逻辑
  }

  afterPlayerInit() {
    // TODO 播放器调用start初始化播放源之后的逻辑
  }

  afterCreate() {
    console.log(this.config)

    this.rePlay = () => {
      this.config.rePlay && this.config.rePlay()
    }

    this.toNext = () => {
      this.config.toNext && this.config.toNext()
    }

    this.jumpAi = () => {
      this.config.jumpAi && this.config.jumpAi()
    }

    this.ret = this.find('.tryPlugin-ret')
    this.next = this.find('.tryPlugin-next')
    this.ai = this.find('.tryPlugin-ai')
    !this.config.isNext && this.next && (this.next.style.display = 'none')

    this.bind('.tryPlugin-ret', 'click', this.rePlay)
    this.bind('.tryPlugin-next', 'click', this.toNext)
    this.bind('.tryPlugin-ai', 'click', this.jumpAi)
  }

  destroy() {
    this.unbind('.tryPlugin-ret', 'click', this.rePlay)
    this.unbind('.tryPlugin-next', 'click', this.toNext)
    this.unbind('.tryPlugin-ai', 'click', this.jumpAi)
    this.ret = null
    this.next = null
    this.ai = null
  }

  render() {
    return `
      <div class="absolute z-[100] w-full h-full flex justify-end items-center">
        <div style="background: rgba(0,0,0,0.8);" class="flex flex-col justify-center items-center h-full w-full">
        <strong class="no-cursor">
          <div class="tryPlugin-ret bg-white anim_btn text-#636880 flex_center text-[14px] w-[169px] h-[42px] rounded-full btn_mb">重播</div>
          <div class="tryPlugin-next bg-white anim_btn text-#636880 flex_center text-[14px] w-[169px] h-[42px] rounded-full btn_mb">下一节</div>
          <div class="tryPlugin-ai submit_btn anim_btn text-white flex_center text-[14px] w-[169px] h-[42px] rounded-full">AI创作</div>
          </strong>
        <div>
      </div>
    `
  }
}
