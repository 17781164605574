import { useState } from 'react'
import useAuthAction from '@/hooks/useAuthAction'
import { toast } from 'react-toastify'
import { orderActivate } from '@/api/course'
import { useAsyncFn, useToggle } from 'react-use'
import Modal from '@/components/Modal'
import { useAtom } from 'jotai'
import { exchangeModalState } from '@/store/global'

export default function Index({ ret }: { ret?: () => void }) {
  const [exchangeModal, setExchangeModal] = useAtom(exchangeModalState)
  const [code, setCode] = useState('')

  const [orderActivateData, orderActivateFetch] = useAsyncFn<() => Promise<any>>(async () => {
    if (code === '') {
      toast('请输入兑换码')
      return ''
    }
    const res = await orderActivate({ code })
    setCode('')
    if (res.code !== 200) {
      toast(res.msg)
      return ''
    }
    toast.success(res.msg)
    setExchangeModal(false)
    ret && ret()
    return res.data
  }, [code])

  return (
    <Modal show={exchangeModal} cancel={() => setExchangeModal(false)}>
      <div className="bg-white text-center rounded-[20px] w-[542px] h-[232px] px-[21px] pt-[23px]">
        <div className="text-[17px] commonColor leading-none mb-[26px] mt-[20px]">请输入随盒兑换码</div>
        <input
          className="w-full h-[53px] rounded-[5px] border-[#5C62EA] border-[1px] border-solid text-[16px] px-[10px] mb-[20px]"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          type="text"
          name=""
          id=""
        />
        <div className="flex justify-center">
          <div
            className="w-[169px] h-[42px] rounded-full bg-[#F2F2F2] text-[18px] text-[#000000] flex_center border-[#9B9B9B] mr-[51px] border-[1px] border-[#9B9B9B] anim_btn text-[#000000]"
            onClick={() => setExchangeModal(false)}
          >
            取消
          </div>
          <div className="w-[169px] h-[42px] rounded-full submit_btn text-[18px] text-white flex_center submit_btn anim_btn" onClick={orderActivateFetch}>
            兑换
          </div>
        </div>
      </div>
    </Modal>
  )
}
