import type { AxiosRequestConfig, AxiosInstance } from 'axios'
import axios from 'axios'
import { routers } from '@/router'
import { toast } from 'react-toastify'
import { headerInfo } from './tool'

export interface Res {
  code: 0 | 1 | 200 | 577 | 578
  data: any
  msg: string
}

const service: AxiosInstance = axios.create({
  baseURL: '',
  withCredentials: true,
  timeout: 12000
})

service.interceptors.request.use(
  // @ts-ignore
  (config: AxiosRequestConfig) => {
    const { headers } = config
    if (headers) {
      headers['deviceId'] = headerInfo.deviceId
      headers['deviceType'] = headerInfo.deviceType
      headers['appVersion'] = headerInfo.appVersion
      if (localStorage.token) {
        headers['Authorization'] = localStorage.token
      }
    }
    console.log(config)
    return config
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (res) => {
    const contentType = res.headers['content-type']
    if (
      contentType.startsWith('application/octet-stream') ||
      contentType.startsWith('application/msexcel') ||
      contentType.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    ) {
      return Promise.resolve(res)
    }
    return Promise.resolve(res.data)
  },
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      // message.error('登录超时，请重新登录！')
      console.log(location)
      localStorage.token = ''
      localStorage.user = ''
      routers.navigate(`/login?from=${location.pathname}${location.search}`, { replace: true })
      return Promise.reject(error.message)
    }
    error.message !== 'canceled' && toast(error?.response?.data?.msg || error.message)
    return Promise.reject(error?.response?.data?.msg || error.message)
  }
)

export default service
